import React, { useState } from 'react';
import copylogoImg from '../images/copy_logo.png';


const Footer = () => {
  const [isActive, setActive] = useState(false);

  const toggleFooter = () => {
    setActive(!isActive);
  };

  const footerClassName = `Footer ${isActive ? 'active' : ''}`;

  return (
    <div className={footerClassName}>
      <div className="footerWrap">
        <button className='toggleBtn' onClick={toggleFooter}></button>
        <div className='copyInfo'>
          <img src={copylogoImg} alt='logo img' />
          <p>Copyright@2024 Epics. All Rights Reserved</p>
        </div>
        
        <div className='companyInfo'>
          <p className='tit'>CREATIVE TOTAL DESIGN GROUP</p>
          <div className='contact'>
            <p><span>T</span>02-549-3993</p>
            <p><span>F</span>02-549-3883</p>
            <p><span>E</span>epic2014@naver.com</p>
          </div>
          <p className='add'>04788 서울시 성동구 광나루로 144 더스페이스타워 1301호</p>
          <p className='add eng'>1301 THESPACETOWER, 144, GWANGNARU-RO,<br />SEONGDONG-GU, SEOUL, KOREA</p>
        </div>

      </div>
    </div>
  );
}

export default Footer;
