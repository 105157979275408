import { Link } from 'react-router-dom';
import MenuBtn from './MenuBtn';
import logoImg from '../images/logo.png';

const Header = () => {
  return (
    <div className="Header">
      <div className='headerWrap'>
        <Link to="/">
          <img src={logoImg} alt='logo img' />
        </Link>
        <MenuBtn />
      </div>
      
    </div>
  );
}
export default Header;