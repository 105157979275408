import React, { useState, useEffect } from 'react';
import AxiosApi from '../api/AxiosApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Zoom, Autoplay, Pagination, Navigation, Virtual } from 'swiper/modules';

const SlidePop = ({ onClose, boardId }) => {

  const [portfolioDtail, setPortfolioDtail] = useState();
  const [portfolioTitle, setPortfolioTitle] = useState('');
  const [showInfoBox, setShowInfoBox] = useState(true);

  // 리스트
  useEffect(() => {
    const getPortfolioDtail = async () => {
      try {
        const resp = await AxiosApi.portfolioDetailGet(boardId);
        setPortfolioDtail(resp.data.data.records);
        setPortfolioTitle(resp.data.data.detail);
        console.log(resp.data.data.records);

        setTimeout(() => {
          setShowInfoBox(false);
        }, 4000);

      } catch (e) {
        console.error(e);
      }
    };
    getPortfolioDtail();
  }, [boardId]);
  
  const swiperOptions = {
    lazy: true,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    // autoplay: {
    //   delay: 2500, 
    //   disableOnInteraction: false,
    // },
    pagination: {
      dynamicBullets: false,
    },
    navigation: true,
    zoom: true,
    allowTouchMove: true, // 터치 이벤트 허용
  };
  
  const popClose = () => {
    showAddressBar();
    onClose();
  };

  useEffect(() => {
    hideAddressBar();

    return () => {
      showAddressBar();
    };
  }, []);

  const hideAddressBar = () => {
    window.scrollTo(0, 1);
  };

  const showAddressBar = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='SlidePop'>
      {portfolioDtail && (
        <div className='popWrap'>
          <div className='popTit'>
            <h2>{portfolioTitle.projectName}</h2>
            <button className='closeBtn' onClick={popClose}>팝업 닫기</button>
          </div>
          <div className={`infoBox ${showInfoBox ? '' : 'hidden'}`}>
            <img src="/img/img_phone.png" alt="가로모드권장" />
          </div>
          
          <Swiper
            modules={[Zoom, Autoplay, Pagination, Navigation, Virtual]}
            className="MainSwiper"
            {...swiperOptions}
          >
            {portfolioDtail.map((data, index) => (
              <SwiperSlide key={index} onClick={popClose}>
                {/* <a href={data.img} target='_blink'>
                  <img src={data.img} alt={data.orgImg} />
                </a> */}
                <img src={data.img} alt={data.orgImg} />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <div className='dim' onClick={popClose}></div>
    </div>
  );
};

export default SlidePop;
