import MainSwiper from "../component/MainSwiper";

const Main = () => {

  return (
    <div className="Main">
      <MainSwiper />
    </div>
  );
}
export default Main;