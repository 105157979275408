
import React from 'react';
import styled from 'styled-components';

const Item = styled.button`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: #111;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: rgba(99, 167, 190, 0.8);
  object-fit: cover; 
  z-index:99;
  img {
    width: 32px;
    height: auto
  }

  @media only screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
`;


const NasBtn = () => {
  const openNas = () => {
    const nasUrl = 'http://epics.synology.me:5000/#/signin';
    window.open(nasUrl, '_blank');
  };

  return (
    <Item onClick={openNas}>
      <img src='/img/btn_web.png' alt='웹서버 이동' />
    </Item>
  );
};


export default NasBtn;
