import React, { useRef } from 'react';
import TopBtn from '../component/TopBtn';
import Pdfdown from '../component/Pdfdown';
import NasBtn from '../component/NasBtn';

const About = () => {
  const aboutRef = useRef(null);

  const scrollTopBtn = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className='subPage'>
      <div className='titArea'>
        <h1>ABOUT</h1>
      </div>
      <div className='contents' ref={aboutRef}>
        
        <div className='aboutCont firstItem'>
          <h2>Who we are</h2>
          <div className='cont'>
            <h3>CREATIVE TOTAL DESIGN GROUP</h3>
            <ul className='listTy1'>
              <li>저희 에픽스는 창조적이고 감각적인 토탈디자인 그룹입니다.</li>
              <li>고객의 성공적 비즈니스와 발전을 위한 최고의 파트너로서</li>
              <li>차별화된 시장의 해석과 창의적인 디자인, 다양한 건축물의 최상의 시각적인 표현과</li>
              <li>효율적인 워크타임 관리로 최대한의 고객만족 실현을 위해 끊임없이 발전하고 노력하겠습니다.</li> 
            </ul>
          </div>
        </div>

        <div className='aboutCont'>
          <h2>How we work</h2>
          <div className='cont'>
            <h3>ARCHITECTURAL VISUALISATION</h3>
            <ul className='listTy2'>
              <li>아파트, 상업시설, 업무시설, 호텔, 공장, 관공서 등 분양 홍보용 그래픽</li>
              <li>공공주택, 교육시설 현상설계 및 BLT용 그래픽 및 도판</li>
              <li>건축물 익스테리어 및 인테리어 조감도, 투시도, ISO, 입면도, 배치도 그래픽</li>
              <li>공공시설물, 조경시설, 조형물 그래픽</li>
              <li>TURN-KEY PROJECT</li> 
            </ul>

            <h3>ENVIRONMENT DESIGN</h3>
            <ul className='listTy2'>
              <li>아파트 주거환경 특화디자인</li>
              <li>상업시설, 업무시설, 호텔, 관공서 등 각종 건축물 외관 특화 디자인 및 경관디자인</li> 
            </ul>
          </div>
        </div>

      </div>
      <div className="quickBtn">
        <NasBtn />
        <Pdfdown />
        <TopBtn onClick={scrollTopBtn} />
      </div>
      
    </div>
  );
}

export default About;