import axios from "axios";

// https://admin.epics.co.kr/api/v1/getMainList
// https://admin.epics.co.kr/api/v1/getAllProjectList
// .then(function(response){console.log(response)}).catch().finally()
// https://admin.epics.co.kr/api/v1/getProjectDetail/{projectId}
// https://admin.epics.co.kr/api/v1/getDownPdfInfo
const AxiosApi = {
	calladdr:'https://admin.epics.co.kr',
	mainListGet:async () => {
		return await axios.get(AxiosApi.calladdr + `/api/v1/getMainList`);
	},
	portfolioListGet:async () => {
		return await axios.get(AxiosApi.calladdr + `/api/v1/getAllProjectList`);
	},
	portfolioDetailGet:async (projectId) => {
		return await axios.get(AxiosApi.calladdr + `/api/v1/getProjectDetail/${projectId}`);
	},
	portfolioPDFGet:async () => {
		return await axios.get(AxiosApi.calladdr + `/api/v1/getDownPdfInfo`);
	},
}

export default AxiosApi;