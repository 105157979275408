
import React from 'react';
import styled from 'styled-components';

const Item = styled.button`
  width: 44px;
  height: 44px;
  color: #111;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: rgba(99, 167, 190, 0.8);
  object-fit: cover; 
  z-index:99;

  @media only screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
`;

const TopBtn = ({ onClick }) => {
  return (
    <Item onClick={onClick}>▲</Item>
  );
};

export default TopBtn;
