import { useState, useEffect } from "react";
import AxiosApi from '../api/AxiosApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';
import styled from 'styled-components';
import NasBtn from '../component/NasBtn';
import Pdfdown from '../component/Pdfdown';

const StyledSwiperSlide = styled(SwiperSlide)`
  img { 
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: 50%;
  }
`;

export default function MainSwiper() {

  const [mainList, setMainList] = useState(); // 메인 목록

  useEffect(()=>{
    const fetchMainList = async () => {
      try {
        const resp = await AxiosApi.mainListGet();
        console.log(resp.data.data.records);
        setMainList(resp.data.data.records);
      } catch(e) {
        console.log(e);
      }
    };
    fetchMainList();
  },[]);

  return (
    <>
      <Swiper
        lazy={true}
        spaceBetween={30}
        effect={'fade'}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: false,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="MainSwiper"
      >
        {mainList &&
          mainList.map((records)=>(
            <StyledSwiperSlide key={records.projectId}>
              <img
                src={records.img ? records.img : "/img/posco1.jpg"}
                alt={records.projectName}
                loading="lazy"
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            </StyledSwiperSlide>
          ))}
          <div className="quickBtn">
            <NasBtn />
            <Pdfdown />
          </div>
          
      </Swiper>
      
    </>
  );
}
