import React, { useRef, useEffect } from 'react';
import TopBtn from '../component/TopBtn';
import Pdfdown from '../component/Pdfdown';
import NasBtn from '../component/NasBtn';

const Contact = () => {
  const contactRef = useRef(null);
  const naverMapRef = useRef(null);

  const initNaverMap = () => {
    // Naver 지도 API 불러오기
    const script = document.createElement('script');
    script.src = 'https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=qzyuds9mn3&submodules=geocoder';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.naver && window.naver.maps) {
        const mapOptions = {
          center: new window.naver.maps.LatLng(37.5512433, 127.0464692), // 초기 맵 중심 좌표 (위도, 경도)
          zoom: 17, // 초기 줌 레벨
        };

        const map = new window.naver.maps.Map(naverMapRef.current, mapOptions);

        // 주소를 위경도로 변환하여 마커 추가
        const markerOptions = {
          position: new window.naver.maps.LatLng(37.5512433, 127.0464692), // 마커 위치 (위도, 경도)
          map: map,
        };
        // eslint-disable-next-line no-unused-vars
        const marker = new window.naver.maps.Marker(markerOptions);
      }
    };
  };

  const scrollTopBtn = () => {
    if (contactRef.current) {
      contactRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    initNaverMap();
  }, []);

  return (
    <div className='subPage'>
      <div className='titArea'>
        <h1>CONTACT US</h1>
      </div>
      <div className='contents' ref={contactRef}>

        <div className='aboutCont column'>
            <div ref={naverMapRef} style={{ width: '100%', height: '300px' }} />
          <div className='cont'>
            <h2 className='ty2'>(주)디자인에픽스</h2>
            <ul className='listTy1'>
              <li>04788 서울시 성동구 광나루로 144 더스페이스타워 1301호</li>
              <li>1301 THESPACETOWER, 144, GWANGNARU-RO, SEONGDONG-GU, SEOUL, KOREA</li> 
            </ul>
            <div className='contact'>
              <p><span>Tel</span>02-549-3993</p>
              <p><span>Fax</span>02-549-3883</p>
              <p><span>Email</span>epic2014@naver.com</p>
            </div>
          </div>
        </div>

      </div>
      <div className="quickBtn">
        <NasBtn />
        <Pdfdown />
        <TopBtn onClick={scrollTopBtn} />
      </div>
    </div>
  );
}

export default Contact;
