import React, { useRef } from 'react';
import PortfolioItem from '../component/PortfolioItem';
import TopBtn from '../component/TopBtn';
import Pdfdown from '../component/Pdfdown';
import NasBtn from '../component/NasBtn';

const Portfolio = () => {
  

  const portfolioRef = useRef(null);

  const scrollTopBtn = () => {
    if (portfolioRef.current) {
      portfolioRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className='subPage'>
      <div className='titArea'>
        <h1>WORKS</h1>
      </div>
      <div className='Portfolio' ref={portfolioRef}>
        <PortfolioItem />
      </div>
      <div className="quickBtn">
        <NasBtn />
        <Pdfdown />
        <TopBtn onClick={scrollTopBtn} />
      </div>
    </div>
  );
};

export default Portfolio;
