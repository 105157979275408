import React, { useState, useEffect } from 'react';
import AxiosApi from '../api/AxiosApi';
import styled from 'styled-components';
import SlidePop from './SlidePop';
// import { useNavigate } from "react-router-dom";

const Item = styled.div`
  position: relative;
  width: calc(25% - 4px); 
  height: 160px;
  overflow: hidden;
  &:hover .txt {
    display: flex;
  }
  
  .txt { 
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.7);
    h1 {
      font-size: 14px;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
    }
  }
  img { 
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }

  @media (max-width: 933px) {
    position: relative;
    width: 100%; 
    height: 160px;
    overflow: hidden;
    &:hover .txt {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50%;
    }
  }
`;

const PortfolioItem = () => {
  const [isPopVisible, setPopVisible] = useState(false);
  const [portfolioList, setPortfolioList] = useState();
  const [portfolioId, setPortfolioId] = useState(null);

  useEffect(() => {
    const getPortfolioList = async () => {
      try {
        const resp = await AxiosApi.portfolioListGet();
        console.log(resp.data.data.records);
        setPortfolioList(resp.data.data.records);
      } catch (e) {
        console.error(e);
      }
    };
    getPortfolioList();
  }, []);

  

  const togglePop = (projectId) => {
    // 모바일 기기에서만 동작하도록 변경
    // if (/Mobi|Android/i.test(navigator.userAgent)) {
    //   // 팝업이 열려있을 때는 가로 모드로 전환하지 않음
    //   if (!isPopVisible) {
    //     try {
    //       if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
    //         window.screen.orientation.lock('portrait').catch((error) => {
    //           console.error('Failed to lock orientation', error);
    //         });
    //       } else {
    //         console.error('screen.orientation.lock is not supported on this device');
    //       }
    //     } catch (error) {
    //       console.error('Failed to use screen.orientation', error);
    //     }
    //   }
    // }
    setPopVisible(!isPopVisible);
    setPortfolioId(projectId);
  };
  
  return (
    <>
      {portfolioList &&
          portfolioList.map((records)=>(
            <Item 
              key={records.projectId}
              onClick={() => togglePop(records.projectId)}
            >
              <div className='txt'>
                <h1>{records.projectName}</h1>
              </div>
              <img src={records.img} alt={records.projectName} />
            </Item>
          ))}
          {isPopVisible && <SlidePop onClose={()=> setPopVisible(false)} boardId={portfolioId} />}
    </>
  );
};
export default PortfolioItem;
