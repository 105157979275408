import React, { useState, useEffect } from 'react';
import AxiosApi from '../api/AxiosApi';
import styled from 'styled-components';

const Item = styled.button`
  width: 44px;
  height: 44px;
  color: #111;
  font-size: 0;
  text-align: center;
  border-radius: 50%;
  background: rgba(99, 167, 190, 0.8) ;
  background-size: 20px;
  object-fit: cover; 
  img {
    width: 20px;
    height: auto
  }

    @media only screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
      font-size: 10px;
        img {
          width: 16px;
          height: auto
        }
    }
`;

const Pdfdown = ({ onClick, pdfUrl }) => {
    const [portfolioPDF, setPortfolioPDF] = useState();

  useEffect(() => {
    const getPortfolioList = async () => {
      try {
        const resp = await AxiosApi.portfolioPDFGet();
        setPortfolioPDF(resp.data.data.pdf);
      } catch (e) {
        console.error(e);
      }
    };
    getPortfolioList();
  }, []);

    const pdfDown = async () => {
      try {
          const pdfUrl = portfolioPDF.pdf;
          if (pdfUrl) {
          // Create a link element
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.setAttribute('target', '_blank');
          link.setAttribute('download', 'portfolio.pdf');
          // Append to the document body
          document.body.appendChild(link);
          // Click the link
          link.click();
          // Clean up
          document.body.removeChild(link);
          } else {
            alert('PDF 파일을 찾을 수 없습니다.');
            console.error('PDF URL not found');
          }
      } catch (error) {
          console.error('Error downloading PDF:', error);
      }
    };

    return (
    <Item className='btnDown' onClick={() => pdfDown() }>
        <img src='/img/btn_down.png' alt='PDF 다운로드' />
    </Item>
    );
};

export default Pdfdown;
