import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./css/index.scss";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Main from "./page/Main";
import Portfolio from "./page/Portfolio";
import About from "./page/About";
import Contact from "./page/Contact";

const App = () => {
  useEffect(() => {
    // Function to lock screen orientation to portrait mode
    const lockPortrait = () => {
      try {
        if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
          window.screen.orientation.lock('portrait').catch((error) => {
            console.error('Failed to lock orientation', error);
          });
        } else {
          console.error('screen.orientation.lock is not supported on this device');
        }
      } catch (error) {
        console.error('Failed to use screen.orientation', error);
      }
    };

    lockPortrait(); // Lock orientation when the component mounts

    return () => {
      // Release the orientation lock when the component unmounts
      try {
        if (window.screen && window.screen.orientation && window.screen.orientation.unlock) {
          window.screen.orientation.unlock().catch((error) => {
            console.error('Failed to unlock orientation', error);
          });
        } else {
          console.error('screen.orientation.unlock is not supported on this device');
        }
      } catch (error) {
        console.error('Failed to use screen.orientation', error);
      }
    };
  }, []);

  return (
    <Router>
      <div className="Wrap">
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/" element={<Navigate to="/Portfolio" />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;