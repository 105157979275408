import React, { useRef } from 'react';
import Menu from './Menu';
import { MenuProvider } from './MenuContext';

const MenuBtn = () => {
  const node = useRef();
  return (
    <MenuProvider>
      <div className='Menu' ref={node}>
        <Menu />
      </div>
    </MenuProvider>
  );
};

export default MenuBtn;
