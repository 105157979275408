import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMenu } from './MenuContext';

const StyledBurger = styled.button`
  display: none;

  @media (max-width: 933px) {
    position: absolute;
    top: 24px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10000;

    &:focus {
      outline: none;
    }

    .item {
      width: 20px;
      height: 2px;
      background: ${({ open }) => open ? '#FFFFFF' : '#FFFFFF'};
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      &.ty1 {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      }

      &.ty2 {
        opacity: ${({ open }) => open ? '0' : '1'};
        transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
      }

      &.ty3 {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  position: relative;
  padding: 0;
  text-align: center;
  z-index: 9;

  @media (max-width: 933px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    padding: 90px 20px 30px;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    background:rgba(0,0,0,0.9);
    z-index: 9999;
  }
`;

const StyledLink = styled(Link)`
  color: #cecdcd;
  font-size: 20px;
  font-family: 'Nanum'; 
  font-weight: 700;
  text-decoration: none;
  .point {
    color: #73c6e6;
  }

  @media (max-width: 933px) {
    font-size: 16px;
  }
`;

const Menu = () => {
  const { menuOpen, toggleMenu } = useMenu();
  const menuRef = useRef(null);
  const [menuClosing, setMenuClosing] = useState(false);

  useEffect(() => {
    const allArea = (event) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(event.target) && !menuClosing) {
        toggleMenu();
      }
    };

    document.addEventListener('click', allArea);

    return () => {
      document.removeEventListener('click', allArea);
    };
  }, [menuOpen, toggleMenu, menuClosing]);

  const closeMenu = () => {
    setMenuClosing(true);
    toggleMenu();
  };

  const menuBoxClose = () => {
    setMenuClosing(false);
  };


  return (
    <>
      <StyledBurger ref={menuRef} open={menuOpen} onClick={closeMenu}>
        <div className='item ty1'></div>
        <div className='item ty2'></div>
        <div className='item ty3'></div>
      </StyledBurger>

      <StyledMenu
        ref={menuRef}
        open={menuOpen}
        onClick={closeMenu}
        onTransitionEnd={menuBoxClose}
      >
        <StyledLink to="/About">
          <span aria-label="About us"></span>
          ABOUT
        </StyledLink>
        <StyledLink to="/Portfolio">
          <span aria-label="Portfolio"></span>
          WORKS
        </StyledLink>
        <StyledLink to="/Contact">
          <span aria-label="Contact"></span>
          CONTACT US
        </StyledLink>
      </StyledMenu>
    </>
  );
};

export default Menu;
